<template>
  <v-dialog v-model="deleteOffer.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Archive Offer</v-card-title>
      <v-card-text>Are you sure you wish to archive this?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="deleteOffer.loading"
          @click="saveDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      deleteOffer: {
        dialog: false,
        offer: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(offer) {
      this.deleteOffer.offer = offer;
      this.deleteOffer.dialog = true;
    },

    resetDelete() {
      this.deleteOffer.dialog = false;
      this.deleteOffer.offer = {};
      this.deleteOffer.loading = false;
    },

    saveDelete() {
      this.deleteOffer.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteOffer", {
          appId: this.$route.params.id,
          offerId: this.deleteOffer.offer.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteOffer.loading = false;
        });
    },
  },
};
</script>
